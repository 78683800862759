import { Injectable } from '@angular/core';
import {CanLoad, Router, UrlTree} from '@angular/router';
import { Store } from '@ngxs/store';
import {map, Observable, of, take} from 'rxjs';
import { UserState } from './state/user.state';

/**
 * Biztositjuk hogy a user-nek van mar jelszava
 */
@Injectable({ providedIn: 'root' })
export class RequirePasswordGuard implements CanLoad {
  constructor(private router: Router, private store: Store) {
  }

  canLoad(): Observable<boolean | UrlTree> {
    return this.store.select(UserState.hasPassword).pipe(
      take(1),
      map(hasPassword => hasPassword || this.router.parseUrl('/user/set-password'))
    );
  }
}
