<div
  class="wrapper"
  [ngClass]="{ 'p-5 align-items-center flex-column justify-content-center': isMobileOrTabletDevice }"
>
  <img class="mb-5 mobile-logo" *ngIf="isMobileOrTabletDevice" src="assets/images/citydeals_logo_feher.svg" />
  <div class="left-side" *ngIf="('' | isMobileDevice) === false"
    ><img class="mb-5 desktop-logo" src="assets/images/citydeals_logo_feher.svg" /><img
      style="width: 100%; height: 100%"
      src="/assets/images/login_image.jpg"
  /></div>
  <div class="right-side" [ngClass]="{ 'card p-3': isMobileOrTabletDevice }">
    <h1>{{ titleTranslateKey | transloco }}</h1>
    <ng-content></ng-content>
  </div>
</div>

<div class="layout-footer flex align-items-center p-1 pr-4 pl-4 shadow-2 w-100" *ngIf="isMobileOrTabletDevice">
  <img class="footer-logo" src="assets/images/citydeals_logo_szurke.svg" style="height: 2.25rem" />
  <p class="copyright m-auto">© {{ today | date: 'yyyy' }} CITYDEALS</p>
  <ng-container *ngIf="!('' | isMobile)">
    <a class="mr-5" href="https://citydeals.hu">Átlépés a Citydeals-re</a>
    <a class="mr-5" href="https://ezhovamutasson.com">Támogatás</a>
  </ng-container>
</div>

