import { Component } from '@angular/core';
import { CitydealsFormGroup, IsMobileDevicePipe } from '@partneradmin/common';
import { FormControl, Validators } from '@angular/forms';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { LoginAction } from '../../../state/action/login.action';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {ActivatedRoute, Router} from '@angular/router';
import { MessageService } from 'primeng/api';
import { TranslocoService } from '@ngneat/transloco';

@UntilDestroy()
@Component({
  selector: 'partneradmin-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService],
})
export class LoginComponent {
  readonly emailControl = new FormControl(null, Validators.compose([Validators.required, Validators.email]));
  readonly passwordControl = new FormControl(null, Validators.compose([Validators.required]));
  readonly form = new CitydealsFormGroup({
    email: this.emailControl,
    password: this.passwordControl,
  });
  showPassword = false;
  queryParamMap: object | null = null;

  #store: Store;
  #actions$: Actions;
  #router: Router;
  #messageService: MessageService;
  #translocoService: TranslocoService;

  readonly isMobileOrTabletDevice = new IsMobileDevicePipe().transform(undefined);

  constructor(
    store: Store,
    actions$: Actions,
    router: Router,
    messageService: MessageService,
    translocoService: TranslocoService,
    private route: ActivatedRoute
  ) {
    this.#store = store;
    this.#actions$ = actions$;
    this.#router = router;
    this.#messageService = messageService;
    this.#translocoService = translocoService;

    this.queryParamMap = this.route.snapshot.queryParams;
    this.watchSuccessLogin();
  }

  watchSuccessLogin() {
    this.#actions$
      .pipe(ofActionSuccessful(LoginAction), untilDestroyed(this))
      .subscribe(() => this.#router.navigate(['/'], {queryParams: this.queryParamMap}));
  }

  onSubmit() {
    this.form.submitted = true;
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const { email, password } = this.form.value;
      this.form.disable();
      this.#store.dispatch(new LoginAction(email, password)).subscribe({
        error: error => {
          this.form.enable();
          this.#messageService.add({
            key: 'login-error',
            severity: 'error',
            summary: this.#translocoService.translate('USER.LOGIN.FORM.REMOTE_ERROR_NOTIFI_TITLE'),
            detail: error.error.message,
          });
        },
      });
    }
  }
}
